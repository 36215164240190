
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import Company from "@/models/Company";

interface NavItemInterface {
  label: any,
  state: SettingState
}

enum SettingState {
  EDIT_PAYMENT = 'EDIT_PAYMENT',
}

@Component({
  components: {
    EditCompanyPaymentComponent: () => import(
      /* webpackChunkName: "EditCompanyPaymentComponent" */
      '@/components/Settings/CompanySettings/EditCompanyPayment.component.vue'
    ),
  },
})
export default class EditProfileView extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private company!: Company;

  private currentState = SettingState.EDIT_PAYMENT;

  /**
   * all the different nav items
   * @private
   */
  private navItems: NavItemInterface[] = [
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.GENERAL'), state: SettingState.EDIT_PAYMENT},
  ];

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: string): void {
    this.currentState = value as SettingState;
  }

  private isStateActive(value: string) {
    return this.currentState === value as SettingState;
  }
}
